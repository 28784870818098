/* NOTE: THIS FILE IS AUTOGENERATED - DO NOT EDIT IT DIRECTLY */

type SentryConfig =
  | {
      sentryEnabled: false;
    }
  | {
      sentryEnabled: true;
      sentryDSN: string;
      sentryEnvironment: string;
    };

export type Config = {
  gtmId: string;
  shopifyStoreDomain: string;
  shopifyStorefrontToken: string;
  twlBackendApiBaseUrl: string;
  twlReactApiBaseUrl: string;
  gReCaptchaKey: string;
  facebookOauth2ClientId: string;
  googleOauth2ClientId: string;
  googleReviewBadgeMerchantId: string;
  wisePopsSiteId: string;
  wisePopsNotificationsSiteId: string;
  cometlyUid: string;
} & SentryConfig;


export const config: Config = {"gtmId":"GTM-5CN3TKR","shopifyStoreDomain":"the-whisky-list.myshopify.com","shopifyStorefrontToken":"0f66410306f782a68c3fcd010d292d47","twlBackendApiBaseUrl":"https://backend.thewhiskylist.com.au/api/v1","twlReactApiBaseUrl":"https://thewhiskylist.com.au/react/api/v1","gReCaptchaKey":"6LdeuvIdAAAAACJ2OaDyC5wZAwITRVklfFdwZTpC","facebookOauth2ClientId":"466308797436019","googleOauth2ClientId":"1099458772892-pahb2v19prsbhr1qdjkuakjekqq1ev9d.apps.googleusercontent.com","googleReviewBadgeMerchantId":"132352085","wisePopsSiteId":"nQTS77YHae","wisePopsNotificationsSiteId":"wlGk","cometlyUid":"e9295a-4503599640000024-e5c779-s","sentryEnabled":true,"sentryDSN":"https://8dc41124f23c48d48dc137de82a78a28@o292781.ingest.sentry.io/6330194","sentryEnvironment":"production"}